.SellingPoints {
    width: 100%;
    box-sizing: border-box;
    padding:20px;
    margin-bottom:30px;
    border-left: 2px solid #608512;
}

.SellingPoints {
    background-color:#EEE;
    background-image: linear-gradient(#F9F9F9, #eeeeee);
    border-radius:4px;
}

.SellingPoints ul {
    list-style: none;
    padding-left:1.7rem;
}

.SellingPoints li {
    margin-bottom:15px;
    font-size:1.1rem;
}

.SellingPoints li span {
    font-weight: bold;
}