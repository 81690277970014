.HeroWrapper {
    width: 100%;
    color: #fff;
    display: block;
    margin-top: 120px;
    text-align: center;
    position: relative;
    overflow: hidden;
    height: 500px;
}

.HeroWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 70;
    transition: transform 1.0s ease-out;
}

/* .HeroImageStill img {
    width: 1920px;
    height: 500px;
    bottom: unset;
    right: unset;
    transition: unset;
} */

.HeroImageTransition img:nth-child(2), .HeroImageTransition img:nth-child(3) {
    left: -100%;
}

.HideImage1 img:nth-child(1), .HideImage1 img:nth-child(2),
.HideImage2 img:nth-child(3) {
    transform: translateX(100%);
}

.HideImage2 img:nth-child(2) {
    transform: translateX(200%);
}

.ShowImage1 img:nth-child(1), .ShowImage2 img:nth-child(2), .ShowImage3 img:nth-child(3) {
    left: 0;
    z-index: 80;
    transition: none;
}

.Overlay {
    position: absolute;
    right: 0;
    left:0;
    top: 0;
    bottom:0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 90;
}

.Overlay div {
    width: 90%;
    position: absolute;
    left: 50%;
    margin-left: -45%;
    top: 50%;
    height: 350px;
    margin-top: -175px;
    z-index: 100;
}

.Overlay p, .SellingPoints li {
    text-align: center;
    color: #fff;
    font-size: 1.8rem;
    padding: 0 0 30px 0;
    margin: 0;
    text-shadow: 2px 2px 3px #000;
}

.SellingPoints li {
    padding-bottom: 10px;
}

.Bigger {
    font-size: 2.5rem !important;
    font-weight: 500;
}

.SellingPoints {
    list-style-type: none;
    padding:0;
    margin:0 0 25px 0;
}

.Overlay a {
    margin: 0 10px;
}

.Selectors {
    position: absolute;
    bottom: 20px;
    z-index: 100;
    width: 100%;
    text-align: center;
}

.Selectors span {
    height: 15px;
    width: 15px;
    background-color: rgba(187, 187, 187, 0.4);
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    box-shadow: 3px 3px 3px #333;
}

.Selectors span:hover {
    cursor: pointer;
    background-color: rgba(187, 187, 187, 0.8);
}

@media (max-width: 1340px) {
    .HeroWrapper {
        height: 450px;
    }
    .HeroWrapper img {
        width:120%;
        right:unset;
    }
}

@media (max-width: 1120px) {
    .HeroWrapper {
        height: 400px;
    }
    .Bigger {
        font-size: 2.0rem !important;
    }
    .Overlay div {
        height: 300px;
        margin-top: -150px;
    }
    .Overlay p, .SellingPoints li {
        font-size: 1.4rem;
    }
    .HeroWrapper img {
        width:135%;
    }
}

@media (max-width: 950px) {
    .HeroWrapper {
        height: 370px;
    }
    .HeroWrapper img {
        width:150%;
    }
}

@media (max-width: 780px) {
    .HeroWrapper {
        margin-top: 110px;
    }
    .Overlay div {
        height: 270px;
        margin-top: -135px;
    }
    .Selectors {display:none;}
    .Bigger {
        font-size: 1.6rem !important;
    }
    .Overlay p, .SellingPoints li{
        font-size: 1.2rem;
    }
    .Overlay a {
        font-size: 1.2rem !important;
    }
    .HeroWrapper img {
        width:200%;
    }
}

@media (max-width: 540px) {
    .HeroWrapper {
        margin-top: 110px;
    }
    .Overlay div {
        height: 250px;
        margin-top: -125px;
    }
    .Overlay li {
        font-size: 1rem !important;
    }
    .Bigger {
        font-size: 1.2rem !important;
        padding-bottom:20px !important;
    }
    .SellingPoints {
        margin-bottom:15px;
    }
    .Overlay a {
        font-size: 1rem !important;
        margin-bottom:10px;
    }
    .Selectors {display:none;}
    .HeroWrapper img {
        width:250%;
    }
}

@media (max-width: 450px) {
    .HeroWrapper img {
        width:300%;
        left:-100px;
    }
}