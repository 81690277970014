.NavigationItems {
    display: inline-block;
}

.NavigationItems ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    align-items: center;
}

@media (max-width:1300px) {
    .NavigationItems {
        display: block;
        border-top: 1px solid #ddd;
    }

    .DesktopOnly {
        display: none;
    }

    .NavigationItems ul {
        flex-flow: column;
    }
}