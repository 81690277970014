.Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 120;
    background-color:#fff;
    box-shadow: 0 3px 3px #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 40px;
}