.BottomCallUs {
    position:fixed;
    z-index:100;
    background-color:#0B73B5;
    background-image: linear-gradient(#074670, #0A5C94);
    bottom:0;
    left:0;
    right:0;
    padding:20px 0;
}

.BottomCallUs a {
    font-size:1.2rem;
    background-color: none;
    border: 1px solid #fff;
    color: #fff;
}

.BottomCallUs a:hover {
    background-color: white;
    color: #0B73B5;
}