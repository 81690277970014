footer {
    width: 100%;
    padding: 40px 20px 200px 20px;
    box-sizing: border-box;
    color: #fff;
    background-color: #333;
    background-image: linear-gradient(#333333, #1F1F1F);
    text-align: left;
    color: #fff;
    font-size: 0.9rem;
}

footer p {font-size:1.0rem;}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.grey-bgd, .green-bgd {
    background-color: #3F3F3F;
    padding: 20px;
    border-radius: 4px;
    margin: 0;
    color:#fff;
}

.grey-bgd .field-validation-error-dark {color:#E68A00;}

.green-bgd {
    background-color: #47630E;
}

footer li {
    padding-bottom: 5px;
}

footer a {
    color: #fff;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

footer img {
    width:28px;
    height:32px;
}

footer img:hover {
    opacity: 0.5;
}

.list-style-inline li {display:inline-block;padding:5px;}

@media (min-width: 768px) {
    .list-style-inline li {display:block;padding:0;}
}