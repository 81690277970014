.CallToAction {
    clear: both;
    width: 100%;
    display: block;
    background-color: #034269;
    background-image: linear-gradient(#03517E, #023859);
    color: #fff;
    padding:40px 0;
}

.CallToAction p {
    margin-bottom: 0;
    font-size: 1.25rem;
}