.SideMenu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background-color: #fff;
    transition: transform 0.3s ease-out;
    padding-top:40px;
}

@media (min-width:1300px) {
    .SideMenu {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(100%);
}

.CrossIcon {
    position: fixed;
    top: 10px;
    right: 20px;
    font-size: 20px;
    z-index: 210;
    color: #999;
    cursor: pointer;
}