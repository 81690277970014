.NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
}

.NavigationItem span {
    display:none;
}

.NavigationItem a {
    color: #333;
    text-decoration: none;
    padding: 20px;
    margin: 0 10px 0 10px;
    width: 100%;
    text-align: center;
}

.NavigationItem a:hover {
    background-color: #608512;
    color: #fff;
}

.PhoneNumber {
    border-left: 1px solid #ddd;
    font-size: 1.2rem;
}

.PhoneNumber a {
    color: #608512;
}

.PhoneNumber a img {
    display: inline-block;
    height: 18px;
    margin-right: 5px;
}

@media (max-width:1300px) {
    .NavigationItem {
        width: 100%;
        flex: 1 1 auto;
    }
    .NavigationItem a {
        margin: 0;
        text-align: left;
    }
    .PhoneNumber {
        border-left: 0;
        border-top: 1px solid #ddd;
    }
    .NavigationItem span {
        display: inline-block;
        margin-right: 5px;
    }
    .NavigationItem strong {
        display: none;
    }
}