.Process {
    padding:40px 0 40px 0;
}

.Process h2 {
    color: #6B0CAB;
    text-align: center;
}

.CircleWrapper img {
    margin:0 auto;
    width:80px;
    height:71px;
}

.Circle {
    color: #fff;
    border-radius: 50%;
    padding: 35px;
    background-color: #9E1DF0;
    background-image: linear-gradient(#45076D, #9E1DF0);
    text-align: center;
}

.CircleWrapper p {
    margin-bottom:0;
    padding-bottom:0;
}