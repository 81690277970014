.Logo {
    display:inline-block;
    text-align:center;
}

.Logo img {
    height:70px;
    display:inline-block;
}

.Logo p {
    color:#333;
    font-size:0.85rem;
    padding:0;
    margin:0;
    text-align: center;
}

.SideLogo {
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    flex: 0 0 100%;
}

.SideLogo img {
    height:50px;
}

@media (max-width:780px) {
    .Logo img {height:55px;}
    .Logo p{font-size:0.7rem;}
}