.CookieDisclaimer {
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    z-index: 102;
}

.CookieContainer {
    width: 100%;
    background-color:#0B73B5;
    background-image: linear-gradient(#0B73B5, #095B93);
    color: #fff;
    padding: 30px;
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 2px 2px 5px #333;
    border-radius: 0.3rem;
    position: relative;
}

.CookieDisclaimer p {
    margin:0;
    font-size:1.0rem;
}

.CookieLink {
    color:#fff;
}

.CookieBtn { font-size:1.1rem; }

.CloseBtn {
    position: absolute;
    top: 5px;
    right: 12px;
    z-index: 101;
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 1;
    color: #fff;
    opacity: .8;
}

.CloseBtn:hover {
    opacity: .6;
}

@media (max-width: 992px) {
    .CookieBtn { font-size:1rem; }
}

@media (max-width: 700px) {
    .CookieContainer {padding:22px;}
    .CookieDisclaimer { left:15px;right:15px; }
}

@media (max-width: 450px) {
    .CookieBtn { display:block; }
}