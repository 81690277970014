.Breadcrumb {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 0;
}

.Breadcrumb img {
    width:20px;
    height:26px;
    display:inline-block;
    margin-right:5px;
}

.BreadcrumbList {
    list-style:none;
    padding-left:0;
}

.BreadcrumbList li {
    display:inline-block;
    margin-left:10px;
}

.BreadcrumbList li a {text-decoration: none;}

.BreadcrumbList li a:hover{text-decoration: underline;}

.BreadcrumbList li:not(:first-child):not(:nth-child(2)):not(:only-child)::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: ">";
}