.Stats {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 0;
    background-color: #6A9414;
    background-image: linear-gradient(#6A9414, #4D6A0F);
    color: #fff;
}

.Stats h2 {text-align:center;font-size:2.5rem;font-weight:400;}

.Stats p {
    text-align:center;
    font-size:1.6rem;
    border-radius:4px;
    border:4px solid rgba(0, 0, 0, .1);
    padding:20px;
    background-color:#648A17;
    background-clip:padding-box;
    display:block;
}

.Stats span {
    font-size:3.2rem;
    display:block;
    font-weight:600;
}

.DarkVersion {
    color:#333 !important;
    background-color: #799F2C !important;
}

@media (max-width:768px) {
    .Stats {padding: 20px 0;}
    .Stats p {font-size: 1.4rem;}
    .Stats h2{font-size: 2.0rem;}
}