/* css reset file */
@import-normalize; 

* { font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; }

*, ::after, ::before { box-sizing: border-box; }

body {line-height:1.5;color: #212529;text-align:left;font-weight:400;}

/* text styles */
h1 { font-size: 2.5rem; }

h2 { font-size: 2rem; }

h3 { font-size: 1.75rem; }

h4 { font-size: 1.5rem; }

h1, h2, h3, h4 { padding:0; margin: 0 0 2.0rem 0; font-weight:500; }

h3 {font-weight:400;}

a {color:#0B73B5;text-decoration:none;}

a:hover {text-decoration: underline;}

.text-right { text-align: right; }

.text-center { text-align: center; }

.logo-green { color:#608512;text-decoration:none; }

p { font-size: 1.20rem; font-weight: 300;  }

main h2, main h3 {margin-top:2.0rem;}

main p {margin-bottom:2.0rem;}

main li {font-size:1.2rem;margin-bottom:0.8rem;font-weight:300;}

main ul {margin-bottom:2.0rem;}

.break-word {word-wrap:break-word;}

.font-weight-bold{font-weight:bold;}

.green-block{background-color:#608512;padding:0.5rem;display:inline-block;border-left:4px solid #333;color:#fff;margin-bottom:0.3rem;font-weight:normal;}

blockquote {margin:0 0 2rem 0;color:#333;padding:1rem;border-left:1px solid #DDD;border-bottom:1px solid #DDD;text-align:center;}
blockquote p {font-size:1.0rem;}
.blockquote-footer {display:block;color:#EEE;font-style:italic;padding:1rem;background-color:#333;}
.blockquote-footer cite{font-size:0.9rem;}

.badge-warning {color: #212529;background-color: #ffc107;}
.badge {display:inline-block;padding:0.25em 0.4em;font-size:75%;font-weight:700;line-height:1;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:0.25rem;}
/* ------------- */

/* assets */
img { vertical-align: middle; border-style: none; }

.fluid-width-video-wrapper { height:0;position:relative;padding:0 0 56.25% 0; }

iframe { position:absolute;top:0;left:0;width:100%;height:100%;border:none; }
/* ------------- */

/* grid styles */
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section, blockquote, div {
    display: block;
}

.lazyload-wrapper{display:inline-block;}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row .row { margin:0; }

.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9{position:relative;width:100%;min-height:1px;padding-right:15px;padding-left:15px}
.col-1{flex:0 0 8.333333%;max-width:8.333333%}
.col-2{flex:0 0 16.666667%;max-width:16.666667%}
.col-3{flex:0 0 25%;max-width:25%}
.col-4{flex:0 0 33.333333%;max-width:33.333333%}
.col-5{flex:0 0 41.666667%;max-width:41.666667%}
.col-6{flex:0 0 50%;max-width:50%}
.col-7{flex:0 0 58.333333%;max-width:58.333333%}
.col-8{flex:0 0 66.666667%;max-width:66.666667%}
.col-9{flex:0 0 75%;max-width:75%}
.col-10{flex:0 0 83.333333%;max-width:83.333333%}
.col-11{flex:0 0 91.666667%;max-width:91.666667%}
.col-12{flex:0 0 100%;max-width:100%}

.offset-0 { margin-left: 0; }
.offset-1 { margin-left: 8.333333%; }
.offset-2 { margin-left: 16.666667%; }
.offset-3 { margin-left: 25%; }

.table { width:100%;max-width:100%;margin-bottom:1rem;background-color:transparent;border-collapse:collapse;display:table;border-spacing:2px;border-color:grey;font-size:1.20rem;font-weight:300; }
.table tr {border-bottom:1px solid #CCC;}
.table td, .table th {padding:10px;}
/* ---------------------------------------------- */


/* padding / margin styles */
.p-0 { padding: 0!important;}
.p-1 { padding: .25rem!important; }
.p-2 { padding: .5rem!important; }
.p-3 { padding: 1rem!important; }
.p-4 { padding: 1.5rem!important; }

.pt-0 { padding-top: 0!important;}
.pt-1 { padding-top: .25rem!important; }
.pt-2 { padding-top: .5rem!important; }
.pt-3 { padding-top: 1rem!important; }
.pt-4 { padding-top: 1.5rem!important; }

.pl-0 { padding-left: 0!important;}
.pl-1 { padding-left: .25rem!important; }
.pl-2 { padding-left: .5rem!important; }
.pl-3 { padding-left: 1rem!important; }
.pl-4 { padding-left: 1.5rem!important; }

.pr-0 { padding-right: 0!important;}
.pr-1 { padding-right: .25rem!important; }
.pr-2 { padding-right: .5rem!important; }
.pr-3 { padding-right: 1rem!important; }
.pr-4 { padding-right: 1.5rem!important; }

.pb-0 { padding-bottom: 0!important;}
.pb-1 { padding-bottom: .25rem!important; }
.pb-2 { padding-bottom: .5rem!important; }
.pb-3 { padding-bottom: 1rem!important; }
.pb-4 { padding-bottom: 1.5rem!important; }

.m-0 { margin: 0!important;}
.m-1 { margin: .25rem!important; }
.m-2 { margin: .5rem!important; }
.m-3 { margin: 1rem!important; }
.m-4 { margin: 1.5rem!important; }

.mt-0 { margin-top: 0!important;}
.mt-1 { margin-top: .25rem!important; }
.mt-2 { margin-top: .5rem!important; }
.mt-3 { margin-top: 1rem!important; }
.mt-4 { margin-top: 1.5rem!important; }

.ml-0 { margin-left: 0!important;}
.ml-1 { margin-left: .25rem!important; }
.ml-2 { margin-left: .5rem!important; }
.ml-3 { margin-left: 1rem!important; }
.ml-4 { margin-left: 1.5rem!important; }

.mr-0 { margin-right: 0!important;}
.mr-1 { margin-right: .25rem!important; }
.mr-2 { margin-right: .5rem!important; }
.mr-3 { margin-right: 1rem!important; }
.mr-4 { margin-right: 1.5rem!important; }

.mb-0 { margin-bottom: 0!important;}
.mb-1 { margin-bottom: .25rem!important; }
.mb-2 { margin-bottom: .5rem!important; }
.mb-3 { margin-bottom: 1rem!important; }
.mb-4 { margin-bottom: 1.5rem!important; }
/* ------------- */


/* image classes */
.img-fluid { max-width:100%;height:auto; }

.img-fully-rounded { border-radius:50%;border:3px solid #ccc; }

.pointer { cursor:pointer; }

.hover-opacity:hover { opacity:0.8; }
/* ------------- */


/* card styles */
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-img-top { width: 100%; border-top-left-radius: calc(.25rem - 1px); border-top-right-radius: calc(.25rem - 1px); }

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    height:100%;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {font-weight:300;margin-bottom:0.75rem;font-size:1.2rem;}

.card-text {font-size: 1.0rem;}

hr {margin-top:1rem;margin-bottom:1rem;border:0;border-top:1px solid rgba(0,0,0,.1);}
/* ------------- */

/* button styles */
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-decoration: none;
    cursor:pointer;
}

.btn:hover {
    text-decoration: none;
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning-outline {
    color: #ffc107;
    background-color: transparent;
    border-color: #ffc107;
}

.btn-warning:hover, .btn-warning-outline:hover {
    color: #ffc107;
    background-color: #212529;
    border-color: #212529;
}

.btn-grey {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-grey:hover {
    color: #999;
    background-color: transparent;
    border-color: #999;
}

.btn-grey-outline {
    color: #333333;
    background-color: transparent;
    border-color: #333333;
}

.btn-grey-outline:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    background-color: #999;
    border-color: #999;
}
/* ------------- */


/* form element styles */
.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-label {
    margin-bottom: 0;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.box-shadow{box-shadow:3px 3px 3px #CCC;border:1px solid #CCC;}

.field-validation-error { color: red; display: block;}
.field-validation-error-dark { color: #000; display: block; margin-top:5px; font-weight: 500;}

.position-relative{position:relative;}
.form-submit{position:absolute;top:0;left:0;right:0;bottom:0;;z-index:1;color:#fff;text-align:center;font-size:2.0rem;background-color: rgba(0, 0, 0, 0.8);display:flex;justify-content:center;align-items:center;}
.form-submit span {padding:1rem;border:2px solid #fff;margin:1.2rem;}
/* ------------- */


/* display none / block */
.d-none{display: none;}
.d-block { display: block !important;}
.d-inline-block { display: inline-block !important;}
.w-100{width:100%;}
/* ------------- */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    .container {
        max-width: 540px;
    }
    .col-sm-1{flex:0 0 8.333333%;max-width:8.333333%}
    .col-sm-2{flex:0 0 16.666667%;max-width:16.666667%}
    .col-sm-3{flex:0 0 25%;max-width:25%}
    .col-sm-4{flex:0 0 33.333333%;max-width:33.333333%}
    .col-sm-5{flex:0 0 41.666667%;max-width:41.666667%}
    .col-sm-6{flex:0 0 50%;max-width:50%}
    .col-sm-7{flex:0 0 58.333333%;max-width:58.333333%}
    .col-sm-8{flex:0 0 66.666667%;max-width:66.666667%}
    .col-sm-9{flex:0 0 75%;max-width:75%}
    .col-sm-10{flex:0 0 83.333333%;max-width:83.333333%}
    .col-sm-11{flex:0 0 91.666667%;max-width:91.666667%}
    .col-sm-12{flex:0 0 100%;max-width:100%}

    .offset-sm-0 { margin-left: 0; }
    .offset-sm-1 { margin-left: 8.333333%; }
    .offset-sm-2 { margin-left: 16.666667%; }
    .offset-sm-3 { margin-left: 25%; }

    .p-sm-0 { padding: 0!important;}
    .p-sm-1 { padding: .25rem!important; }
    .p-sm-2 { padding: .5rem!important; }
    .p-sm-3 { padding: 1rem!important; }
    .p-sm-4 { padding: 1.5rem!important; }

    .pt-sm-0 { padding-top: 0!important;}
    .pt-sm-1 { padding-top: .25rem!important; }
    .pt-sm-2 { padding-top: .5rem!important; }
    .pt-sm-3 { padding-top: 1rem!important; }
    .pt-sm-4 { padding-top: 1.5rem!important; }

    .pl-sm-0 { padding-left: 0!important;}
    .pl-sm-1 { padding-left: .25rem!important; }
    .pl-sm-2 { padding-left: .5rem!important; }
    .pl-sm-3 { padding-left: 1rem!important; }
    .pl-sm-4 { padding-left: 1.5rem!important; }

    .pr-sm-0 { padding-right: 0!important;}
    .pr-sm-1 { padding-right: .25rem!important; }
    .pr-sm-2 { padding-right: .5rem!important; }
    .pr-sm-3 { padding-right: 1rem!important; }
    .pr-sm-4 { padding-right: 1.5rem!important; }

    .pb-sm-0 { padding-bottom: 0!important;}
    .pb-sm-1 { padding-bottom: .25rem!important; }
    .pb-sm-2 { padding-bottom: .5rem!important; }
    .pb-sm-3 { padding-bottom: 1rem!important; }
    .pb-sm-4 { padding-bottom: 1.5rem!important; }

    .m-sm-0 { margin: 0!important;}
    .m-sm-1 { margin: .25rem!important; }
    .m-sm-2 { margin: .5rem!important; }
    .m-sm-3 { margin: 1rem!important; }
    .m-sm-4 { margin: 1.5rem!important; }

    .mt-sm-0 { margin-top: 0!important;}
    .mt-sm-1 { margin-top: .25rem!important; }
    .mt-sm-2 { margin-top: .5rem!important; }
    .mt-sm-3 { margin-top: 1rem!important; }
    .mt-sm-4 { margin-top: 1.5rem!important; }

    .ml-sm-0 { margin-left: 0!important;}
    .ml-sm-1 { margin-left: .25rem!important; }
    .ml-sm-2 { margin-left: .5rem!important; }
    .ml-sm-3 { margin-left: 1rem!important; }
    .ml-sm-4 { margin-left: 1.5rem!important; }

    .mr-sm-0 { margin-right: 0!important;}
    .mr-sm-1 { margin-right: .25rem!important; }
    .mr-sm-2 { margin-right: .5rem!important; }
    .mr-sm-3 { margin-right: 1rem!important; }
    .mr-sm-4 { margin-right: 1.5rem!important; }

    .mb-sm-0 { margin-bottom: 0!important;}
    .mb-sm-1 { margin-bottom: .25rem!important; }
    .mb-sm-2 { margin-bottom: .5rem!important; }
    .mb-sm-3 { margin-bottom: 1rem!important; }
    .mb-sm-4 { margin-bottom: 1.5rem!important; }

    .d-sm-none{display:none;}
    .d-sm-block{display:block;}
    .d-sm-inline{display:inline!important;}

    .text-left-sm{text-align:left;}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
    .col-md-1{flex:0 0 8.333333%;max-width:8.333333%}
    .col-md-2{flex:0 0 16.666667%;max-width:16.666667%}
    .col-md-3{flex:0 0 25%;max-width:25%}
    .col-md-4{flex:0 0 33.333333%;max-width:33.333333%}
    .col-md-5{flex:0 0 41.666667%;max-width:41.666667%}
    .col-md-6{flex:0 0 50%;max-width:50%}
    .col-md-7{flex:0 0 58.333333%;max-width:58.333333%}
    .col-md-8{flex:0 0 66.666667%;max-width:66.666667%}
    .col-md-9{flex:0 0 75%;max-width:75%}
    .col-md-10{flex:0 0 83.333333%;max-width:83.333333%}
    .col-md-11{flex:0 0 91.666667%;max-width:91.666667%}
    .col-md-12{flex:0 0 100%;max-width:100%}

    .offset-md-0 { margin-left: 0; }
    .offset-md-1 { margin-left: 8.333333%; }
    .offset-md-2 { margin-left: 16.666667%; }
    .offset-md-3 { margin-left: 25%; }

    .p-md-0 { padding: 0!important;}
    .p-md-1 { padding: .25rem!important; }
    .p-md-2 { padding: .5rem!important; }
    .p-md-3 { padding: 1rem!important; }
    .p-md-4 { padding: 1.5rem!important; }

    .pt-md-0 { padding-top: 0!important;}
    .pt-md-1 { padding-top: .25rem!important; }
    .pt-md-2 { padding-top: .5rem!important; }
    .pt-md-3 { padding-top: 1rem!important; }
    .pt-md-4 { padding-top: 1.5rem!important; }

    .pl-md-0 { padding-left: 0!important;}
    .pl-md-1 { padding-left: .25rem!important; }
    .pl-md-2 { padding-left: .5rem!important; }
    .pl-md-3 { padding-left: 1rem!important; }
    .pl-md-4 { padding-left: 1.5rem!important; }

    .pr-md-0 { padding-right: 0!important;}
    .pr-md-1 { padding-right: .25rem!important; }
    .pr-md-2 { padding-right: .5rem!important; }
    .pr-md-3 { padding-right: 1rem!important; }
    .pr-md-4 { padding-right: 1.5rem!important; }

    .pb-md-0 { padding-bottom: 0!important;}
    .pb-md-1 { padding-bottom: .25rem!important; }
    .pb-md-2 { padding-bottom: .5rem!important; }
    .pb-md-3 { padding-bottom: 1rem!important; }
    .pb-md-4 { padding-bottom: 1.5rem!important; }

    .m-md-0 { margin: 0!important;}
    .m-md-1 { margin: .25rem!important; }
    .m-md-2 { margin: .5rem!important; }
    .m-md-3 { margin: 1rem!important; }
    .m-md-4 { margin: 1.5rem!important; }

    .mt-md-0 { margin-top: 0!important;}
    .mt-md-1 { margin-top: .25rem!important; }
    .mt-md-2 { margin-top: .5rem!important; }
    .mt-md-3 { margin-top: 1rem!important; }
    .mt-md-4 { margin-top: 1.5rem!important; }

    .ml-md-0 { margin-left: 0!important;}
    .ml-md-1 { margin-left: .25rem!important; }
    .ml-md-2 { margin-left: .5rem!important; }
    .ml-md-3 { margin-left: 1rem!important; }
    .ml-md-4 { margin-left: 1.5rem!important; }

    .mr-md-0 { margin-right: 0!important;}
    .mr-md-1 { margin-right: .25rem!important; }
    .mr-md-2 { margin-right: .5rem!important; }
    .mr-md-3 { margin-right: 1rem!important; }
    .mr-md-4 { margin-right: 1.5rem!important; }

    .mb-md-0 { margin-bottom: 0!important;}
    .mb-md-1 { margin-bottom: .25rem!important; }
    .mb-md-2 { margin-bottom: .5rem!important; }
    .mb-md-3 { margin-bottom: 1rem!important; }
    .mb-md-4 { margin-bottom: 1.5rem!important; }

    .d-md-none{display:none;}
    .d-md-block{display:block;}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .container {
        max-width: 960px;
    }
    .col-lg-1{flex:0 0 8.333333%;max-width:8.333333%}
    .col-lg-2{flex:0 0 16.666667%;max-width:16.666667%}
    .col-lg-3{flex:0 0 25%;max-width:25%}
    .col-lg-4{flex:0 0 33.333333%;max-width:33.333333%}
    .col-lg-5{flex:0 0 41.666667%;max-width:41.666667%}
    .col-lg-6{flex:0 0 50%;max-width:50%}
    .col-lg-7{flex:0 0 58.333333%;max-width:58.333333%}
    .col-lg-8{flex:0 0 66.666667%;max-width:66.666667%}
    .col-lg-9{flex:0 0 75%;max-width:75%}
    .col-lg-10{flex:0 0 83.333333%;max-width:83.333333%}
    .col-lg-11{flex:0 0 91.666667%;max-width:91.666667%}
    .col-lg-12{flex:0 0 100%;max-width:100%}

    .offset-lg-0 { margin-left: 0; }
    .offset-lg-1 { margin-left: 8.333333%; }
    .offset-lg-2 { margin-left: 16.666667%; }
    .offset-lg-3 { margin-left: 25%; }

    .p-lg-0 { padding: 0!important;}
    .p-lg-1 { padding: .25rem!important; }
    .p-lg-2 { padding: .5rem!important; }
    .p-lg-3 { padding: 1rem!important; }
    .p-lg-4 { padding: 1.5rem!important; }

    .pt-lg-0 { padding-top: 0!important;}
    .pt-lg-1 { padding-top: .25rem!important; }
    .pt-lg-2 { padding-top: .5rem!important; }
    .pt-lg-3 { padding-top: 1rem!important; }
    .pt-lg-4 { padding-top: 1.5rem!important; }

    .pl-lg-0 { padding-left: 0!important;}
    .pl-lg-1 { padding-left: .25rem!important; }
    .pl-lg-2 { padding-left: .5rem!important; }
    .pl-lg-3 { padding-left: 1rem!important; }
    .pl-lg-4 { padding-left: 1.5rem!important; }

    .pr-lg-0 { padding-right: 0!important;}
    .pr-lg-1 { padding-right: .25rem!important; }
    .pr-lg-2 { padding-right: .5rem!important; }
    .pr-lg-3 { padding-right: 1rem!important; }
    .pr-lg-4 { padding-right: 1.5rem!important; }

    .pb-lg-0 { padding-bottom: 0!important;}
    .pb-lg-1 { padding-bottom: .25rem!important; }
    .pb-lg-2 { padding-bottom: .5rem!important; }
    .pb-lg-3 { padding-bottom: 1rem!important; }
    .pb-lg-4 { padding-bottom: 1.5rem!important; }

    .m-lg-0 { margin: 0!important;}
    .m-lg-1 { margin: .25rem!important; }
    .m-lg-2 { margin: .5rem!important; }
    .m-lg-3 { margin: 1rem!important; }
    .m-lg-4 { margin: 1.5rem!important; }

    .mt-lg-0 { margin-top: 0!important;}
    .mt-lg-1 { margin-top: .25rem!important; }
    .mt-lg-2 { margin-top: .5rem!important; }
    .mt-lg-3 { margin-top: 1rem!important; }
    .mt-lg-4 { margin-top: 1.5rem!important; }

    .ml-lg-0 { margin-left: 0!important;}
    .ml-lg-1 { margin-left: .25rem!important; }
    .ml-lg-2 { margin-left: .5rem!important; }
    .ml-lg-3 { margin-left: 1rem!important; }
    .ml-lg-4 { margin-left: 1.5rem!important; }

    .mr-lg-0 { margin-right: 0!important;}
    .mr-lg-1 { margin-right: .25rem!important; }
    .mr-lg-2 { margin-right: .5rem!important; }
    .mr-lg-3 { margin-right: 1rem!important; }
    .mr-lg-4 { margin-right: 1.5rem!important; }

    .mb-lg-0 { margin-bottom: 0!important;}
    .mb-lg-1 { margin-bottom: .25rem!important; }
    .mb-lg-2 { margin-bottom: .5rem!important; }
    .mb-lg-3 { margin-bottom: 1rem!important; }
    .mb-lg-4 { margin-bottom: 1.5rem!important; }

    .card-title {font-size: 1.1rem;}

    .d-lg-none{display:none;}
    .d-lg-block{display:block;}
    .d-lg-inline{display:inline!important;}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .container {
        max-width: 1140px;
    }
    .col-xl-1{flex:0 0 8.333333%;max-width:8.333333%}
    .col-xl-2{flex:0 0 16.666667%;max-width:16.666667%}
    .col-xl-3{flex:0 0 25%;max-width:25%}
    .col-xl-4{flex:0 0 33.333333%;max-width:33.333333%}
    .col-xl-5{flex:0 0 41.666667%;max-width:41.666667%}
    .col-xl-6{flex:0 0 50%;max-width:50%}
    .col-xl-7{flex:0 0 58.333333%;max-width:58.333333%}
    .col-xl-8{flex:0 0 66.666667%;max-width:66.666667%}
    .col-xl-9{flex:0 0 75%;max-width:75%}
    .col-xl-10{flex:0 0 83.333333%;max-width:83.333333%}
    .col-xl-11{flex:0 0 91.666667%;max-width:91.666667%}
    .col-xl-12{flex:0 0 100%;max-width:100%}

    .offset-xl-0 { margin-left: 0; }
    .offset-xl-1 { margin-left: 8.333333%; }
    .offset-xl-2 { margin-left: 16.666667%; }
    .offset-xl-3 { margin-left: 25%; }

    .p-xl-0 { padding: 0!important;}
    .p-xl-1 { padding: .25rem!important; }
    .p-xl-2 { padding: .5rem!important; }
    .p-xl-3 { padding: 1rem!important; }
    .p-xl-4 { padding: 1.5rem!important; }

    .pt-xl-0 { padding-top: 0!important;}
    .pt-xl-1 { padding-top: .25rem!important; }
    .pt-xl-2 { padding-top: .5rem!important; }
    .pt-xl-3 { padding-top: 1rem!important; }
    .pt-xl-4 { padding-top: 1.5rem!important; }

    .pl-xl-0 { padding-left: 0!important;}
    .pl-xl-1 { padding-left: .25rem!important; }
    .pl-xl-2 { padding-left: .5rem!important; }
    .pl-xl-3 { padding-left: 1rem!important; }
    .pl-xl-4 { padding-left: 1.5rem!important; }

    .pr-xl-0 { padding-right: 0!important;}
    .pr-xl-1 { padding-right: .25rem!important; }
    .pr-xl-2 { padding-right: .5rem!important; }
    .pr-xl-3 { padding-right: 1rem!important; }
    .pr-xl-4 { padding-right: 1.5rem!important; }

    .pb-xl-0 { padding-bottom: 0!important;}
    .pb-xl-1 { padding-bottom: .25rem!important; }
    .pb-xl-2 { padding-bottom: .5rem!important; }
    .pb-xl-3 { padding-bottom: 1rem!important; }
    .pb-xl-4 { padding-bottom: 1.5rem!important; }

    .m-xl-0 { margin: 0!important;}
    .m-xl-1 { margin: .25rem!important; }
    .m-xl-2 { margin: .5rem!important; }
    .m-xl-3 { margin: 1rem!important; }
    .m-xl-4 { margin: 1.5rem!important; }

    .mt-xl-0 { margin-top: 0!important;}
    .mt-xl-1 { margin-top: .25rem!important; }
    .mt-xl-2 { margin-top: .5rem!important; }
    .mt-xl-3 { margin-top: 1rem!important; }
    .mt-xl-4 { margin-top: 1.5rem!important; }

    .ml-xl-0 { margin-left: 0!important;}
    .ml-xl-1 { margin-left: .25rem!important; }
    .ml-xl-2 { margin-left: .5rem!important; }
    .ml-xl-3 { margin-left: 1rem!important; }
    .ml-xl-4 { margin-left: 1.5rem!important; }

    .mr-xl-0 { margin-right: 0!important;}
    .mr-xl-1 { margin-right: .25rem!important; }
    .mr-xl-2 { margin-right: .5rem!important; }
    .mr-xl-3 { margin-right: 1rem!important; }
    .mr-xl-4 { margin-right: 1.5rem!important; }

    .mb-xl-0 { margin-bottom: 0!important;}
    .mb-xl-1 { margin-bottom: .25rem!important; }
    .mb-xl-2 { margin-bottom: .5rem!important; }
    .mb-xl-3 { margin-bottom: 1rem!important; }
    .mb-xl-4 { margin-bottom: 1.5rem!important; }

    .card-title {font-size: 1.4rem;}

    .d-xl-none{display:none;}
    .d-xl-block{display:block;}
}