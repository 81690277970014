.CaseStudies {
    width: 100%;
    display: block;
    padding: 40px 0;
    position: relative;
}

.CaseStudies::before{
    background-color:#608512;
    background-image: linear-gradient(#6A9414, #4D6A0F);
    height:50px;
    left:0;
    top:60px;
    right:0;
    position:absolute;
    content:"";
    z-index:-1;
}

.CaseStudies img {
    padding:0.5rem;
    border:1px solid #eee;
    border-radius:2px;
    background-color:#fff;
}

.TextOverlay {
    position:absolute;
    z-index:10;
    background-color: #333;
    background-image: linear-gradient(#333333, #1F1F1F);
    color:#fff;
    bottom:17px;
    right:30px;
    padding:5px;
    font-size:1.0rem;
    border-radius:4px;
}

.CaseStudies h2 {
    padding:5px;
    background-color:#333;
    background-image: linear-gradient(#333333, #1F1F1F);
    color:#fff;
    text-align:center;
}

.CaseStudies hr {
    margin: 2.0rem 0 2.0rem 0;
}

.CaseStudies ul {
    padding-left:20px;
}

.CaseStudies li {
    font-size:1.1rem;
    padding-bottom:0.9rem;
    list-style:none;
    font-weight:300;
}

.CaseStudies li img {
    height:18px;
    padding:0;
    border:none;
    border-radius:unset;
    display:inline-block;
    margin-bottom:0.25rem;
}

@media (max-width: 992px)
{
    .CaseStudies li {
        padding-bottom:1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 992px)
{
    .CaseStudies ul {
        padding-left:4.0rem;
    }
}

@media (max-width: 768px)
{
    .CaseStudies li {
        font-size:1.1rem;
    }
}